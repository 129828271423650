<template>
    <section class="container"></section>
</template>
<script setup lang="ts">
const localePath = useLocalePath();
const { logOut } = useIdentity();
const initializeState = useInitializeState();

await logOut();
await initializeState();
navigateTo(localePath("/"));
</script>
